import * as React from 'react';
import { Grid, Link } from '@mui/material';
import Para from '../../components/Para';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub'
import { colors } from '../../config/constants';
import { TakeSurveyBtn } from '../../components';
export default function Footer() {

    let aboutme = ("Ekommo is a microSaaS project created with a simple goal – to help small businesses and service providers easily collect, manage, and share customer feedback. Designed for simplicity and ease of use, Ekommo enables businesses to gather valuable insights from their customers in just a few clicks. ")
    return (
        <Grid
            container

            sx={{
                backgroundColor: colors.titleColor,
                height: "auto",
                width: "100% ",
                p: 5,
                justifyContent: "space-evenly",
                marginTop: 5

            }}
        >

            <Grid item md={3} sx={10} style={{ marginTop: 10, textAlign: "left" }}>
                <Para text="About Ekommo" color="#fff" customStyle={{ display: "block", fontWeight: "bold", fontSize: "4vh" }} />
                <Para text={aboutme} color="#fff" customStyle={{ fontWeight: "300" }} />


            </Grid>
            <Grid item md={3} sx={10} style={{ marginTop: 10, textAlign: "left" }}>
                <Para text="Stay Connected" color="#fff" customStyle={{ display: "block", fontWeight: "bold", fontSize: "4vh" }} />
                <Para text=" Follow us on social media for updates, news, and a behind-the-scenes look at what we are  working on! " customStyle={{ fontWeight: "300" }} color="#fff" />


                <Grid display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} marginTop={6}>
                    <Link href="https://www.instagram.com/code.azhar/" color={colors.primary} target="_blank" m={2}>
                        <InstagramIcon style={{ color: colors.primary }} />
                    </Link>
                    <Link href="https://github.com/muhammadazhariqbal" color={colors.primary} target="_blank" m={2}>
                        <GitHubIcon style={{ color: colors.primary }} />
                    </Link>
                    <Link href="https://www.linkedin.com/in/muhammadazhariqbal/" color={colors.primary} target="_blank" m={2}>
                        <LinkedInIcon style={{ color: colors.primary }} />
                    </Link>

                    {/* <InstagramIcon style={{ color: colors.primary }} />
                    <InstagramIcon style={{ color: colors.primary }} /> */}

                </Grid >
            </Grid >
            <Grid item md={3} sx={10} style={{ marginTop: 10, textAlign: "left" }}>
                <Para text="Join the Waitlist & Be Notified" color=" #fff" customStyle={{ display: "block", fontWeight: "bold", fontSize: "4vh" }} />
                <Para text="Get notified when we launch and be among the first to experience EKOMMO." customStyle={{
                    display: "block",
                    fontWeight: "300"
                }} color="#fff" />
                <TakeSurveyBtn variant={colors.primary} colorText="#fff" />

            </Grid>



        </Grid >
    );
}