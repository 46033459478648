import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { colors } from '../config/constants';

export default function FeatureBtn({ icon, text }) {
    return (
        <Grid sx={{
            height: { xs: 'auto', md: 'auto' },
            width: '100%',
            borderRadius: '10px',
            border: '0.5px solid rgba(255, 255, 255, 0.16)',
            background: colors.white,

            padding: '10px',


            boxShadow: `0px 8px 16px #edf8f5`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 2,
            justifyContent: "space-between"
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                height: { xs: 'auto', md: '4vh' },
                width: { xs: 'auto', md: '4vh' },
                mr: 2,
            }}>

                {icon}


            </Box>
            <Box sx={{
                fontWeight: '300',
                width: '95%',
                marginTop: 2
            }}>
                <p style={{ color: '#000', margin: 0 }}>{text}</p>
            </Box>
        </Grid>
    );
}