import * as React from 'react';
import Box from '@mui/material/Box';

import { Grid } from '@mui/material';
import { HowItWorksItem, Title } from '../../components';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LinkIcon from '@mui/icons-material/Link';
import TryIcon from '@mui/icons-material/Try';
import Para from '../../components/Para';
import { colors } from '../../config/constants';
export default function HowItWorks() {
    return (
        <Box
            sx={{
                // backgroundImage: `url(${featureImg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: 'auto',
                width: '100%',
            }}
        >
            <Grid container sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignContent: "center", marginTop: 10 }}>
                <Title text="How it Works" color={colors.titleColor} align="center" />

                <Grid container md={10} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", height: "auto", flexWrap: "wrap", p: { xs: 2, md: 3 } }}>

                    {[
                        {
                            num:
                                <Title text={"1"} color={colors.primary} align="center" customStyle={{}} />

                            , text: "Select from a range of ready-made feedback templates tailored to your needs.",
                            title: "Choose a Template",
                        },
                        {
                            num: <Title text={"2"} color={colors.primary} align="center" customStyle={{}} />,
                            title: " Share the Link",
                            text: "Generate a feedback link with just a few clicks and share it with your customers."
                        },
                        {
                            num: <Title text={"3"} color={colors.primary} align="center" customStyle={{}} />,
                            title: "Collect and Share Feedback",
                            text: "Receive feedback instantly and analyze responses. Share positive reviews directly on social media. "
                        },

                    ].map((el, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index} sx={{ display: 'flex', justifyContent: 'center', p: 1, }}>
                            <HowItWorksItem number={el.num} text={el.text} title={el.title} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Box >
    );
}