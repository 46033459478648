import * as React from 'react';
import { Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
export default function Para({ text, color, align, customStyle }) {
    const theme = createTheme();

    theme.typography.p = {
        fontSize: '3.5vh',
        '@media (max-width:500px)': {
            fontSize: '2.9vh',
        },
        fontFamily: 'League Spartan, sans-serif',
        // [theme.breakpoints.up('md')]: {
        //     fontSize: '0.6rem',
        // },
        lineHeight: 1.4


    };
    return (
        <ThemeProvider theme={theme}>

            <Typography fontWeight={400} variant='p' color={color} textAlign={align} style={{ ...customStyle }}>
                {text}
            </Typography>
        </ThemeProvider >

    );

}