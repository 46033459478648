import './App.css';

import { Features, Footer, Header, HowItWorks, PricingSection } from './sections';

function App() {
  return (
    <div className="App">
      <Header />

      <Features />
      <HowItWorks />
      <PricingSection />
      <Footer />


    </div>
  );
}

export default App;
