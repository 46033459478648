import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

export default function HowItWorksItem({ number, text, title }) {
    return (
        <Grid sx={{
            height: { xs: 'auto', md: 'auto' },
            width: '100%',
            display: "flex",
            flexDirection: "column",





        }}>
            <Grid sx={{


                height: { xs: 'auto', md: 'auto' },
                width: { xs: 'auto', md: 'auto' },

            }}>

                {number}


            </Grid>
            <Grid sx={{
                fontWeight: '300',
                width: '95%',
            }}>


                <p style={{ color: '#000', margin: 0, fontWeight: "bold" }}>{title}</p>
                <p style={{ color: '#000', margin: 2 }}>{text}</p>
            </Grid>
        </Grid>
    );
}