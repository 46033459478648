const colors = {
    // primary: "#001D3D",
    // primary: "#001D3D",
    // secondary: "#ED5221",
    // rate: "#FCCE05",
    // textColor: "#000814",
    // white: "#fff",
    // black: "#000000",
    // bgColor: "#F2F2F2"
    primary: "#18A87C",
    white: "#FFF",
    secondary: "#FCCE05",
    rate: "#FCCE05",
    titleColor: "#172327",
    backgroundColor: "#EDF8F5",
    paraColor: "#454F52",
    bgColor: "#F2F2F2"


}



export { colors }