
import * as React from 'react';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../config/firebase';
function TakeSurveyBtn({ variant, colorText }) {
    const theme = useTheme();
    const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Button onClick={() => {
            logEvent(analytics, 'GET EARLY ACCESS EVENT');

            window.open("https://forms.gle/2ZHqdpS6bdNeanK5A", "_blank", "noreferrer");
        }} style={{ marginTop: isSmallerThanMd ? 5 : 10, height: isSmallerThanMd ? 35 : 40, width: isSmallerThanMd ? 170 : 180, fontWeight: "bold", color: colorText, backgroundColor: variant, borderRadius: '10px', fontSize: "1.5vh" }} startIcon={<RocketLaunchIcon />}>
            Get Early Access
        </Button>

    );
}

export default TakeSurveyBtn;