import * as React from 'react';
import Box from '@mui/material/Box';

import { Grid } from '@mui/material';
import { FeatureBtn, Title } from '../../components';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LinkIcon from '@mui/icons-material/Link';
import TryIcon from '@mui/icons-material/Try';
import { colors } from '../../config/constants';

export default function Features() {
    return (
        <Box
            sx={{
                // backgroundImage: `url(${featureImg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: 'auto',
                width: '100%',
            }}
        >
            <Grid container sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignContent: "center", marginTop: 10 }}>
                <Title text="Benefits" color={colors.titleColor} align="center" />
                <Grid container md={10} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", height: "auto", flexWrap: "wrap", p: { xs: 2, md: 3 } }}>

                    {[
                        { icon: <LooksOneIcon style={{ color: colors.primary, height: 30, width: 30, display: "block" }} />, text: "Collect customer feedback effortlessly by selecting a template and sharing a link." },
                        { icon: <LooksTwoIcon style={{ color: colors.primary, height: 30, width: 30, }} />, text: "Build trust and credibility by showcasing what your customers love." },
                        {
                            icon: <Looks3Icon style={{ color: colors.primary, height: 30, width: 30, }} />, text: "Ekommo offers ready-made templates for quick feedback collection."
                        },
                        { icon: <Looks4Icon style={{ color: colors.primary, height: 30, width: 30, }} />, text: "Understand customer sentiment with built-in analytics. " },
                        { icon: <Looks5Icon style={{ color: colors.primary, height: 30, width: 30, }} />, text: "Promote positive feedback to boost your brand visibility on social platforms." },
                        { icon: <Looks6Icon style={{ color: colors.primary, height: 30, width: 30, }} />, text: "Turn positive feedback into engaging social media posts." },
                    ].map((el, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index} sx={{ display: 'flex', justifyContent: 'center', p: 1, }}>
                            <FeatureBtn icon={el.icon} text={el.text} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Box >
    );
}