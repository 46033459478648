import * as React from 'react';
import { AppBar, Grid, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { TakeSurveyBtn } from '../../components';
// import logo from '../../assets/images/ekommo-logo.png'
import logoBlack from '../../assets/images/ekommo-logo-n.svg'
import icon from '../../assets/images/icon-transparent.png'
// import black from '../../assets/images/ekommo-nonwhite.png'
import logoWhite from '../../assets/images/ekommo-logo-white-n.svg'
import { colors } from '../../config/constants';
function NavBar() {
    const theme = useTheme();
    const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));
    //navbar scroll when active state
    const [navbar, setNavbar] = React.useState(false)

    //logo scroll when active
    const [navbarLogo, setNavbarLogo] = React.useState(logoBlack)

    //navbar scroll changeBackground function
    const changeBackground = () => {

        if (window.scrollY >= 66) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    React.useEffect(() => {
        changeBackground()
        // adding the event when scroll change background
        window.addEventListener("scroll", changeBackground)
    })

    //logo scroll function
    const changeLogo = () => {
        if (window.scrollY >= 60) {
            setNavbarLogo(logoWhite)

        } else {
            setNavbarLogo(logoBlack)

        }
    }

    React.useEffect(() => {
        changeLogo()
        // adding the event when scroll change Logo
        window.addEventListener("scroll", changeLogo)
    })

    return (
        <AppBar position="fixed" sx={{ width: '100%', p: 1, backgroundColor: navbar ? colors.titleColor : "#fff", boxShadow: 0, border: "none" }}>
            <Toolbar>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        {
                            isSmallerThanMd ?
                                <img src={icon} alt='logo' style={{ maxHeight: 30 }} />
                                :
                                <img src={navbarLogo} alt='logo' style={{ maxHeight: 20 }} />

                        }
                    </Grid>
                    <Grid item sx={{ backgroundColor: "black" }}>

                    </Grid>
                    {
                        // !isSmallerThanMd &&
                        <Grid item>
                            <TakeSurveyBtn variant={colors.primary} colorText="#fff" />
                        </Grid>
                    }

                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default NavBar;