import * as React from 'react';
import { Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
export default function Title({ text, color, align, customStyle }) {
    const theme = createTheme({
        typography: {
            fontFamily: 'League Spartan, sans-serif',
        }
    });

    theme.typography.h1 = {
        fontSize: '8vh',
        fontWeight: "bold",
        '@media (max-width:500px)': {
            fontSize: '6vh',

        },
        fontFamily: 'League Spartan, sans-serif'

        // [theme.breakpoints.up('md')]: {
        //     fontSize: '0.6rem',
        // },


    };


    return (
        <ThemeProvider theme={theme}>

            <Typography variant='h1' color={color} fontWeight={"bold"} style={{ ...customStyle }} textAlign={align} marginBottom={4}>
                {text}
            </Typography>
        </ThemeProvider>

    );

}